body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  align-content: center;
  box-sizing: border-box;
  font-family: "Press Start 2P";
}

.main {
  background: #ebe8db;
}

.container {
  width: 90%;
  margin: 0 auto;
}

.clear {
  clear: both;
}

nav ul {
  float: right;
}

nav ul,
nav ul li {
  margin: 0;
  padding: 0;
}

nav ul li {
  list-style: none;
  float: left;
  margin-top: 15px;
}

nav ul li a {
  font-size: 12px;
  padding: 0 15px;
}

.main > div {
  width: 100%;
  display: block;
}

main {
  height: 95vh;
  display: flex !important;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

main h1 {
  font-size: 20px;
}

main p {
  font-size: 12px;
  color: #3e3e3e;
}

#portfolio {
  padding: 40px 0;
}

#portfolio ul {
  margin: 0 !important;
  font-size: 15px;
}
#portfolio ul li {
  line-height: 35px;
}
#portfolio ul li ul {
  padding-bottom: 20px;
}

#portfolio .nes-list.is-disc li::before {
  top: 8px !important;
}

